<template>
  <div class="layout">
    <header class="header">
      <div class="container">
        <div class="header-content">
          <div class="header-top">
            <NuxtLink to="/" class="logo">
              <img src="/files/logo_transparent_background.png" alt="Nick Gerner Logo" class="logo-image" />
            </NuxtLink>
            <div class="mobile-controls">
              <client-only>
                <button @click="toggleColorMode" class="color-mode-toggle" aria-label="Toggle dark mode">
                  <span v-if="isDarkMode">☀️</span>
                  <span v-else>🌙</span>
                </button>
              </client-only>
              <button @click="toggleMobileMenu" class="mobile-menu-toggle" aria-label="Toggle menu">
                <span class="hamburger" :class="{ 'is-active': mobileMenuOpen }">
                  <span></span><span></span><span></span>
                </span>
              </button>
            </div>
          </div>
          <nav class="nav" :class="{ 'mobile-nav-open': mobileMenuOpen }">
            <ul class="nav-list">
              <li><NuxtLink to="/" class="nav-link" @click="closeMobileMenu">Home</NuxtLink></li>
              <li><NuxtLink to="/about" class="nav-link" @click="closeMobileMenu">About</NuxtLink></li>
              <li><NuxtLink to="/resume" class="nav-link" @click="closeMobileMenu">Resume</NuxtLink></li>
              <li><NuxtLink to="/blog" class="nav-link" @click="closeMobileMenu">Blog</NuxtLink></li>
              <li><NuxtLink to="/terminal" class="nav-link" @click="closeMobileMenu">Terminal</NuxtLink></li>
              <li><NuxtLink to="/contact" class="nav-link" @click="closeMobileMenu">Contact</NuxtLink></li>
            </ul>
          </nav>
          <div class="header-actions">
            <client-only>
              <AuthNav />
            </client-only>
            <client-only>
              <button @click="toggleColorMode" class="color-mode-toggle desktop-only" aria-label="Toggle dark mode">
                <span v-if="isDarkMode">☀️</span>
                <span v-else>🌙</span>
              </button>
            </client-only>
          </div>
        </div>
      </div>
    </header>

    <main class="main">
      <div class="container">
        <!-- Loading indicator overlay -->
        <Transition name="fade">
          <div v-if="isLoading" class="loading-overlay">
            <div class="loading-spinner">
              <div class="spinner"></div>
              <span>Loading...</span>
            </div>
          </div>
        </Transition>
        
        <client-only>
          <div class="app-container" :class="{ 'dark': isDarkMode }">
            <slot />
          </div>
        </client-only>
        <div v-if="false" class="app-container">
          <slot />
        </div>
      </div>
    </main>

    <footer class="footer">
      <div class="container">
        <div class="footer-content">
          <div class="footer-left">
            <div class="footer-logo-container">
              <img src="/files/logo_transparent_background.png" alt="Nick Gerner Logo" class="footer-logo-image" />
              <p>&copy; {{ new Date().getFullYear() }} Nick Gerner</p>
            </div>
          </div>
          <div class="footer-right">
            <div class="social-links">
              <a href="https://twitter.com/njgerner" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                <span class="social-icon">X</span>
              </a>
              <a href="https://linkedin.com/in/njgerner" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                <span class="social-icon">in</span>
              </a>
              <a href="https://github.com/njgerner" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
                <span class="social-icon">GH</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { computed, onMounted, watch, ref } from 'vue'
import { SpeedInsights as _ } from "@vercel/speed-insights/nuxt"
import { isClient } from '~/utils/clientSide'

const colorMode = useColorMode()
const { isAuthenticated, login } = useAuth()
const mobileMenuOpen = ref(false)
const isLoading = ref(true)
const nuxtApp = useNuxtApp()

// Force colorMode to start with server rendering default
// This ensures server and client render the same initial state
colorMode.preference = 'light'

// Computed property for dark mode
const isDarkMode = computed(() => colorMode.value === 'dark')

// Use useHead to manage the dark class on html
useHead({
  htmlAttrs: {
    class: computed(() => colorMode.value === 'dark' ? 'dark' : '')
  }
})

// Page loading hooks
onMounted(() => {
  // Hide loader when initial page is mounted
  setTimeout(() => {
    isLoading.value = false
  }, 200) // Short delay to ensure content is rendered
  
  // After hydration, check system/user preference
  if (isClient) {
    const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches
    const storedPreference = localStorage.getItem('nuxt-color-mode')
    
    // Respect stored preference or fall back to system preference
    if (storedPreference) {
      colorMode.preference = storedPreference
    } else if (systemPrefersDark) {
      colorMode.preference = 'dark'
    }
  }
})

// Show loading indicator on page transitions
nuxtApp.hook('page:start', () => {
  isLoading.value = true
})

nuxtApp.hook('page:finish', () => {
  setTimeout(() => {
    isLoading.value = false
  }, 200) // Short delay to ensure content is rendered
})

function toggleColorMode() {
  // Simply toggle the preference - useHead will handle the class
  colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark';
}

function toggleMobileMenu() {
  mobileMenuOpen.value = !mobileMenuOpen.value;
  if (mobileMenuOpen.value) {
    document.body.classList.add('mobile-menu-active');
  } else {
    document.body.classList.remove('mobile-menu-active');
  }
}

function closeMobileMenu() {
  mobileMenuOpen.value = false;
  document.body.classList.remove('mobile-menu-active');
}

function triggerLogin() {
  // Save the blog/new path for redirect after login
  useState('authRedirectPath', () => '/blog/new');
  // Trigger login
  login();
}

// Close mobile menu on route change
watch(() => useRoute().path, () => {
  closeMobileMenu();
});
</script>

<style scoped>
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Loading indicator styles */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(var(--color-background-rgb, 255, 255, 255), 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
}

.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 3px solid var(--color-border);
  border-top: 3px solid var(--color-primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
  overflow-x: hidden;
}

.header {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: var(--color-background);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1rem 0;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.mobile-controls {
  display: none;
}

.mobile-menu-toggle {
  display: none;
}

.desktop-only {
  display: flex;
}

.logo {
  text-decoration: none;
  color: var(--color-primary);
  display: flex;
  align-items: center;
}

.logo-image {
  height: 40px;
  width: auto;
}

.logo h1 {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
}

.nav-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 1.5rem;
}

.nav-link {
  text-decoration: none;
  color: var(--color-text);
  font-weight: 500;
  transition: color 0.2s ease;
}

.nav-link:hover, .nav-link.router-link-active {
  color: var(--color-primary);
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.color-mode-toggle {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.25rem;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
}

.color-mode-toggle:hover {
  background-color: var(--color-border);
}

.main {
  flex: 1;
  padding: 2rem 0;
}

.footer {
  background-color: var(--color-background);
  border-top: 1px solid var(--color-border);
  padding: 2rem 0;
  margin-top: auto;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-logo-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer-logo-image {
  height: 30px;
  width: auto;
}

.social-links {
  display: flex;
  gap: 1rem;
}

.social-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: var(--color-muted);
  color: var(--color-background);
  font-weight: bold;
  transition: background-color 0.2s ease;
}

.social-icon:hover {
  background-color: var(--color-primary);
}

@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    gap: 0;
  }
  
  .mobile-controls {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .mobile-menu-toggle {
    display: block;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
  }
  
  .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 24px;
    height: 18px;
  }
  
  .hamburger span {
    display: block;
    height: 2px;
    width: 100%;
    background-color: var(--color-text);
    transition: all 0.3s ease;
  }
  
  .hamburger.is-active span:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  
  .hamburger.is-active span:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger.is-active span:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
  
  .nav {
    display: none;
    width: 100%;
  }
  
  .mobile-nav-open {
    display: block;
    margin-top: 1rem;
  }
  
  .nav-list {
    flex-direction: column;
    gap: 0;
  }
  
  .nav-link {
    display: block;
    padding: 0.75rem 0;
    border-bottom: 1px solid var(--color-border);
  }
  
  .header-actions {
    display: none;
  }
  
  .desktop-only {
    display: none;
  }
  
  .nav-item-with-dropdown .dropdown-menu {
    position: static;
    display: block;
    min-width: 100%;
    box-shadow: none;
    border: none;
    border-radius: 0;
    padding: 0;
    margin-left: 1rem;
  }
  
  .dropdown-item {
    padding: 0.5rem 0;
  }
  
  body.mobile-menu-active {
    overflow: hidden;
  }
  
  .main {
    padding: 1rem 0;
  }
  
  .nav-list {
    gap: 0;
  }
  
  .footer-content {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
}

.nav-item-with-dropdown {
  position: relative;
}

.nav-item-with-dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 180px;
  background-color: var(--color-background);
  border: 1px solid var(--color-border);
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
  z-index: 20;
}

.dropdown-item {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: var(--color-text);
  transition: background-color 0.2s ease;
}

.dropdown-item:hover {
  background-color: var(--color-border);
  color: var(--color-primary);
}

.auth-required-item {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auth-badge {
  font-size: 0.7rem;
  background-color: var(--color-primary);
  color: white;
  padding: 0.2rem 0.4rem;
  border-radius: 4px;
  font-weight: 500;
}
</style> 