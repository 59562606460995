<template>
  <div class="auth-nav">
    <client-only>
      <!-- Show loading state -->
      <div v-if="isLoading" class="auth-loading">Loading...</div>
      
      <!-- Authenticated user view -->
      <div v-else-if="isAuthenticated" class="auth-user">
        <div class="user-profile" @click="toggleDropdown">
          <img v-if="user && user.picture" :src="user.picture" alt="Profile" class="user-avatar" />
          <div v-else class="user-avatar-placeholder">
            {{ userInitial }}
          </div>
          <span class="user-name">{{ userName }}</span>
          <div class="dropdown-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M6 9l6 6 6-6"/>
            </svg>
          </div>
        </div>

        <div v-if="dropdownOpen" class="user-dropdown">
          <div class="dropdown-header">
            <div class="dropdown-avatar">
              <img v-if="user && user.picture" :src="user.picture" alt="Profile" />
              <div v-else class="avatar-placeholder">{{ userInitial }}</div>
            </div>
            <div class="dropdown-user-info">
              <p class="dropdown-user-name">{{ userName }}</p>
              <p v-if="user && user.email" class="dropdown-user-email">{{ user.email }}</p>
            </div>
          </div>
          <div class="dropdown-menu">
            <NuxtLink to="/profile" class="dropdown-item">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
              Profile
            </NuxtLink>
            <NuxtLink to="/blog/new" class="dropdown-item">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M12 5v14M5 12h14"/>
              </svg>
              New Blog Post
            </NuxtLink>
            <NuxtLink to="/admin/ai-questions" class="dropdown-item">
              <span class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                </svg>
              </span>
              AI Questions
            </NuxtLink>
            <div class="dropdown-divider"></div>
            <button @click="logout" class="dropdown-item logout">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                <polyline points="16 17 21 12 16 7"></polyline>
                <line x1="21" y1="12" x2="9" y2="12"></line>
              </svg>
              Logout
            </button>
          </div>
        </div>
      </div>
      
      <!-- Login button (not authenticated) -->
      <button v-else @click="handleLogin" class="login-btn">
        <span class="btn-text">Login</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"/>
          <polyline points="10 17 15 12 10 7"/>
          <line x1="15" y1="12" x2="3" y2="12"/>
        </svg>
      </button>
    </client-only>
    
    <!-- Placeholder for server-side rendering that will be replaced on client -->
    <div v-if="false" class="auth-loading"></div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted, watch, onBeforeMount } from 'vue';
import { useAuth } from '~/composables/useAuth';
import { useNuxtApp, useState } from '#app';

// Define a type for Auth0 user properties we're using
interface Auth0User {
  name?: string;
  nickname?: string;
  email?: string;
  picture?: string;
  [key: string]: any;
}

const nuxtApp = useNuxtApp();
const dropdownOpen = ref(false);

// Default values for server-side rendering
const serverSideAuth = {
  user: ref<Auth0User | null>(null),
  isAuthenticated: ref(false),
  isLoading: ref(false),
  login: async (): Promise<void> => {}, 
  logout: (): void => {}
};

// Use these for reactivity, we'll update them in onBeforeMount if we're client-side
const user = ref<Auth0User | null>(null);
const isAuthenticated = ref(false);
const isLoading = ref(false);
let login = serverSideAuth.login;
let logout = serverSideAuth.logout;

// Initialize auth only on client-side
onBeforeMount(() => {
  if (typeof window !== 'undefined') {
    const auth = useAuth();
    user.value = auth.user.value;
    isAuthenticated.value = auth.isAuthenticated.value;
    isLoading.value = auth.isLoading.value;
    login = auth.login;
    logout = auth.logout;
    
    // Watch for changes in auth state
    watch(() => auth.user.value, (newValue) => {
      user.value = newValue;
    });
    
    watch(() => auth.isAuthenticated.value, (newValue) => {
      isAuthenticated.value = newValue;
    });
    
    watch(() => auth.isLoading.value, (newValue) => {
      isLoading.value = newValue;
    });
  }
});

// Get redirect path if set - use Nuxt's built-in useState
const authRedirectPath = useState<string | null>('authRedirectPath', () => null);

// Simple login function that uses the standard Auth0 login
async function handleLogin(): Promise<void> {
  try {
    console.log('Login button clicked');
    if (typeof window !== 'undefined') {
      await login();
    }
  } catch (error) {
    console.error('Error in login:', error);
  }
}

const userInitial = computed(() => {
  if (!user.value || !user.value.name) return '?';
  return user.value.name.charAt(0).toUpperCase();
});

const userName = computed(() => {
  if (!user.value) return 'User';
  return user.value.name || user.value.nickname || user.value.email?.split('@')[0] || 'User';
});

function toggleDropdown() {
  dropdownOpen.value = !dropdownOpen.value;
}

function closeDropdown(e: MouseEvent): void {
  if (typeof window === 'undefined') return;
  
  try {
    const dropdown = document.querySelector('.auth-nav');
    if (dropdown && e.target instanceof Node && !dropdown.contains(e.target as Node)) {
      dropdownOpen.value = false;
    }
  } catch (error) {
    console.error('Error in closeDropdown:', error);
  }
}

onMounted(() => {
  if (typeof window !== 'undefined') {
    try {
      // Use a small timeout to ensure elements are fully mounted before adding listeners
      setTimeout(() => {
        document.addEventListener('click', closeDropdown);
      }, 100);
    } catch (error) {
      console.error('Error adding click event listener:', error);
    }
  }
});

onUnmounted(() => {
  if (typeof window !== 'undefined') {
    try {
      document.removeEventListener('click', closeDropdown);
    } catch (error) {
      console.error('Error removing click event listener:', error);
    }
  }
});
</script>

<style scoped>
.auth-nav {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.auth-loading {
  font-size: 0.9rem;
  color: var(--color-muted);
}

.user-profile {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: var(--color-background);
  border: 1px solid var(--color-border);
}

.user-profile:hover {
  background-color: var(--color-border);
}

.user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.user-avatar-placeholder {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--color-primary);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.user-name {
  font-size: 0.9rem;
  font-weight: 500;
}

.dropdown-icon {
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
}

.user-dropdown {
  position: fixed;

  width: 250px;
  background-color: var(--color-background);
  border-radius: 8px;
  border: 1px solid var(--color-border);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
}

.dropdown-header {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border-bottom: 1px solid var(--color-border);
}

.dropdown-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.avatar-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-primary);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.dropdown-user-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.dropdown-user-name {
  font-weight: 600;
  font-size: 0.95rem;
  margin: 0;
}

.dropdown-user-email {
  font-size: 0.8rem;
  color: var(--color-muted);
  margin: 0;
}

.dropdown-menu {
  padding: 0.5rem 0;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  color: var(--color-text);
  text-decoration: none;
  transition: background-color 0.2s ease;
  font-size: 0.9rem;
  cursor: pointer;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
}

.dropdown-item:hover {
  background-color: var(--color-background-alt);
}

.dropdown-divider {
  height: 1px;
  background-color: var(--color-border);
  margin: 0.5rem 0;
}

.logout {
  color: #ef4444;
}

.login-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: 30px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.login-btn:hover {
  background-color: var(--color-primary-dark, var(--color-primary));
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(-1px);
}

.btn-text {
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .user-name {
    display: none;
  }
  
  .login-btn .btn-text {
    display: none;
  }
  
  .login-btn {
    padding: 0.5rem;
  }
}
</style> 